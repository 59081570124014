import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { colors, Divider } from '@mui/material';
import Section from 'components/organisms/Section/Section';
import SEO from '../../components/SEO';
import { Hero, Hero2, Hub, Advantages } from './components';

import { advantages } from './data';

export const Head = () => (
  <SEO description="Correlate measured sections and sedimentary logs with interpretation tools for sedimentologists." />
);

const useStyles = makeStyles((theme) => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  shape: {
    background: theme.palette.alternate.main,
    borderBottomRightRadius: '50%',
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
}));

const Sedlogs = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.shape}>
        <Section className={classes.pagePaddingTop}>
          <Hero />
        </Section>
        <Section className={classes.sectionNoPaddingTop}>
          <Hub />
        </Section>
        <Section className={classes.sectionNoPaddingTop}>
          <Hero2 />
        </Section>
      </div>{' '}
      <Section>
        <Advantages data={advantages} />
      </Section>
      <Divider />
    </div>
  );
};

export default Sedlogs;
